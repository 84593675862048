import React from "react";
import "./app.scss";

import Tabs from "./components/Tabs";
import { ReactComponent as Car } from "./Assets/Icons/directions_car.svg";
import HeaderWithMenu from "./components/HeaderWithMenu";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import LoginScreen from "./Screens/LoginScreen/LoginScreen";
import MenuScreen from "./Screens/MenuScreen/MenuScreen";
import LicencePlateScreen from "./Screens/LicencePlateScreen/LicencePlateScreen";
import AuthWrapper from "./AuthWrapper";
import CartScreen from "./Screens/CartScreen/CartScreen";
import HistoryScreen from "./Screens/HistoryScreen/HistoryScreen";
import FinishScreen from "./Screens/FinishScreen/FinishScreen";
import { ScannerProvider } from "./Hooks/useQRScanner";
import PendingCartScreen from "./Screens/PendingCartScreen/PendingCartScreen";
import HistoryProductsScreen from "./Screens/HistoryProductsScreen/HistoryProductsScreen";

import { UserContextProvider } from "./Context/userContext";
import { ShoppingCartProvider } from "./Context/ShoppingCartContext";
import { HistoryProvider } from "./Context/HistoryContext";
import { LoadingContextProvider } from "./Context/LoadingContext";
import { CancelaContextProvider } from "./Context/CancelaContext";
import './ensure-basename';
function App() {
  const router = createBrowserRouter([
    {
      path: "/menu",
      element: (
        <AuthWrapper>
          <MenuScreen></MenuScreen>
        </AuthWrapper>
      ),
    },
    {
      path: "/login",
      element: <LoginScreen></LoginScreen>,
    },

    {
      path: "/licence-plate",
      element: (
        <AuthWrapper>
          <LicencePlateScreen></LicencePlateScreen>
        </AuthWrapper>
      ),
    },
    {
      path: "/cart",
      element: (
        <AuthWrapper>
          <CartScreen></CartScreen>
        </AuthWrapper>
      ),
    },
    {
      path: "/history",
      element: (
        <AuthWrapper>
          <HistoryScreen></HistoryScreen>
        </AuthWrapper>
      ),
    },
    {
      path: "/pending-cart/:index",
      element: (
        <AuthWrapper>
          <PendingCartScreen></PendingCartScreen>
        </AuthWrapper>
      ),
    },
    {
      path: "/history-products/:index",
      element: (
        <AuthWrapper>
          <HistoryProductsScreen></HistoryProductsScreen>
        </AuthWrapper>
      ),
    },
    {
      path: "/history-products:index",
      element: (
        <AuthWrapper>
          <HistoryProductsScreen></HistoryProductsScreen>
        </AuthWrapper>
      ),
    },
    {
      path: "/finish",
      element: (
        <AuthWrapper>
          <FinishScreen></FinishScreen>
        </AuthWrapper>
      ),
    },

    {
      path: "*",
      element: <Navigate to="/login" replace />,
    },
  ], {basename:"/app"});

  return (
    <div className="app">
      <LoadingContextProvider>
      <ShoppingCartProvider>
        <UserContextProvider>
          <CancelaContextProvider>
          <ScannerProvider>
            <HistoryProvider>
            <RouterProvider router={router} />
            </HistoryProvider>
          </ScannerProvider>
          </CancelaContextProvider>
        </UserContextProvider>
      </ShoppingCartProvider>
      </LoadingContextProvider>
    </div>
  );
}

export default App;
