import React, { useEffect, useState } from "react";
import TextInput from "./../../components/TextInput";
import Modal from "./../../components/Modal";
import "./ModalApiConfig.css";
import { useUser } from "./../../Context/userContext";

export default function ModalApiConfig({ isHidden, onClose }) {
  const { User } = useUser();
  const { updateUser, updateUserTerminal } = useUser();
  const [terminalId, setTerminalId] = useState(User.terminalId);
  const [storeId, setStoreId] = useState(User.storeId);
  const [urlApi, setUrlApi] = useState(User.urlApi);



  return (
    <>
      <Modal
        onClose={onClose}
        isHidden={isHidden}
        cancel={"CANCEL"}
        confirm={"GUARDAR"}
        onConfirm={() => {
          updateUser({
            ...User,
            terminalId: terminalId,
            storeId: storeId,
            urlApi: urlApi,
          });
          onClose();
        }}
      >
        <div className="modal-api-config-container">
          <p className="api-config-input-label">ID TERMINAL</p>
          <TextInput
            value={terminalId}
            key={1}
            placeHolder={"Value"}
            onChange={(e) => setTerminalId(e)}
          ></TextInput>
          <p className="api-config-input-label" style={{ marginTop: "20px" }}>
            ID LOJA
          </p>
          <TextInput
            value={storeId}
            key={12}
            placeHolder={"Value"}
            onChange={(e) => setStoreId(e)}
          ></TextInput>
          <p className="api-config-input-label" style={{ marginTop: "20px" }}>
            URL Api
          </p>
          <TextInput
            key={15}
            value={urlApi}
            placeHolder={"Value"}
            onChange={(e) => setUrlApi(e)}
          ></TextInput>
        </div>
      </Modal>
    </>
  );
}
