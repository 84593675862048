import React from "react";
import Modal from "./../../components/Modal";
import "./ModalDeleteProduct.css"


export default function ModalDeleteProduct({ isHidden,onClose,onConfirm }) {

    const modalContent = () => {
        return (
            <div className="modal-delete-product-container">
                {/* <p className="modal-delete-product-name">Nome do Produto</p> */}
                <h3 className="modal-delete-question">Tem a certeza que deseja  apagar o produto?</h3>
  
            </div>
        )
    }

    return (
        <>
            <Modal children={modalContent()}onConfirm={()=>onConfirm(true)} onClose={()=>onClose(true)} cancel={"NĀO"} confirm={"SIM"}  isHidden={isHidden} ></Modal>
        </>
    );
}
