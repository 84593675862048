import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useLocalStorage } from './useLocalStorage';
import { APICall } from '../API';
import { useUser } from './userContext';
import { useLoadingSpinner } from './LoadingContext';

const INITIALCONTEXT = {
   
};
    

const CancelaContext = createContext(
    INITIALCONTEXT
);

function CancelaContextProvider({ children }) {
    const [listaCancelas, setListaCancelas] = useLocalStorage("Cancela", []);
    const User = useUser();
const {setLoading} = useLoadingSpinner()
    useEffect(() => {
       if(listaCancelas.length == 0){
        fetchCancelas()
       }
    }
    , [User.User.storeId]);

    const fetchCancelas = async () => {
      APICall.getCancelas(User.User.storeId).then(res=>{
        setListaCancelas(res)
   
      })
    }
    const openCancelaFromMacAddress = async (mac_address) => {
      setLoading(true)
      APICall.openCancela(User.User.storeId,mac_address).then(res=>{
        
      }).catch(err=>{
   
      }).finally(()=>{
        setLoading(false)
      } )

    }

    return (
        <CancelaContext.Provider
          value={{
            listaCancelas,  
            fetchCancelas,
            openCancelaFromMacAddress
          }}
        >
          {children}
        </CancelaContext.Provider>
      );
}
    
function useCancela() {
    const context = useContext(CancelaContext);
    
    if (!context) {
    throw new Error(
        'useCancela must be used within an CancelaContextProvider'
    );
    } 
    return context;
}
    
export { CancelaContextProvider, useCancela };