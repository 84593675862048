import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ReactComponent as LicenceEU } from "../../Assets/LicenceEU.svg";
import "./LicencePlateInput.css";
import "./overide.css"
const Input = ({ value, onChange, valid, invalid, className, ...props }) => {
  return (
    <input
      className={classNames(
        "rm-text-input mc-text-input",
        { "is-valid": valid, "is-invalid": invalid },
        className
      )}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

const LeftIconInput = ({ className, ...props }) => {
  const [inputValue, setInputValue] = useState("AA 00 AA");

  const handleChange = (e) => {
    const formattedValue = formatLicensePlate(e.target.value);
    setInputValue(formattedValue);
  };

  const formatLicensePlate = (value) => {
    // Remove any non-alphanumeric characters
    const alphanumericValue = value.replace(/[^A-Za-z0-9]/g, "");

    // Insert spaces between every two characters
    const formattedValue = alphanumericValue
      .slice(0, 6)
      .replace(/(.{2})/g, "$1 ")
      .trim(); // Trim any leading/trailing spaces

    return formattedValue.toUpperCase();
  };

  return (
    <div className="LicencePlate">
    <div className="sandbox">
      <div className="mc-left-icon-input">
        <LicenceEU
          style={{
            position: "absolute",
            zIndex: 99,
            height: "63px",
          }}
        />
        <Input
          className={classNames("mc-left-icon-input__input", className)}
          value={inputValue}
          onChange={handleChange}
          style={{
            boxShadow: "none",
			
            height: "63px",
            textAlign: "center",
            fontSize: "40px",
          }}
          maxLength={8}
          {...props}
        />
      </div>
    </div>
    </div>
  );
};

const LicencePlateInput = ({ Icon, ...props }) => <LeftIconInput {...props} />;

Input.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Input.defaultProps = {
  value: "",
  className: "",
  valid: false,
  invalid: false,
};

LeftIconInput.propTypes = {
  Icon: PropTypes.func.isRequired,
  className: PropTypes.string,
};

LeftIconInput.defaultProps = {
  className: "",
};

export default LicencePlateInput;
