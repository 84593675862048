import React, {useEffect} from "react";
import LicencePlateSimpleInput from "../../components/LicencePlateSimpleInput";
import Button from "../../components/Button";
import { ReactComponent as Car } from "../../Assets/Icons/car.svg";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Context/userContext";

import "./LicencePlateScreen.css";
export default function LicencePlateScreen() {
  const navigate = useNavigate();
  const User = useUser();

  return (
    <>
   <div style={{marginTop:84}}></div>
    <div
     className="LicencePlate"
    >
      <h4 className="LicenceTitle">Introduza a Matrícula</h4>
      <Car></Car>
      {/*<LicencePlateInput></LicencePlateInput>*/}
      <LicencePlateSimpleInput></LicencePlateSimpleInput>



      <div style={{ position: "absolute", bottom: 20 }}>
        <Button variant="primary" disabled={User.User.matricula.length < 5} onClick={() => {navigate("/cart");}}>
          SEGUINTE
        </Button>
      </div>
    </div>
    </>
  );
}
