import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ArrowArrowBottom32 } from "@mozaic-ds/icons/react/index";
import { ReactComponent as ReceiptLong } from "../../Assets/Icons/receipt_long.svg";

import "./Card.css";

const prefix = "mc-card--";

const Card = ({
  text = "Histórico",
  children,
  bordered,
  variant,
  size,
  full,
  className,
  Icon,
  iconRight,
  valid,
  invalid,
  onClick=()=>{},
  ...props
}) => {
  const [value, setValue] = useState(0);
  const [variantClass, setVariantClass] = useState("");
  const [sizeClass, setSizeClass] = useState("");

  useEffect(() => {
    const variantClass = `${prefix}${bordered ? "bordered" : "solid"}${
      variant && variant !== "primary" ? `-${variant}` : ""
    }`;
    setVariantClass(variantClass);
  }, [bordered, variant]);

  useEffect(() => {
    const sizeClass = `${prefix}${size ? size : "m"}`;
    setSizeClass(sizeClass);
  }, [size]);

  return (
    <div className="card" style={{width:"100%"}}>
      <div class="sandbox">
        <div class="mc-card" onClickCapture={onClick}>
          <div class="mc-card__icon">
            {Icon ? <Icon></Icon> : <ReceiptLong fill="#188703"></ReceiptLong>}
          </div>
          <h1 class="mc-card__title">{text}</h1>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  bordered: PropTypes.bool,
  full: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  Icon: PropTypes.func,
  iconRight: PropTypes.bool,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
};

Card.defaultProps = {
  children: null,
  bordered: false,
  full: false,
  variant: "",
  className: "",
  size: "",
  Icon: null,
  iconRight: false,
  valid: true,
  invalid: false,
};

export default Card;
