import React, { useState } from 'react';

import './select.css'
import './overide.css'

/*
El options debe ser un array de jsons [{value:"value",label:"label"}]
*/

const Select = ({ options,placeholder,label,requiredText,helperText, ...props }) => {

	return (
		<div class="mc-field mc-field-select">
			<label class="mc-field__label" for="foo">
				{label}
			</label>
			{requiredText && <span class="mc-field__requirement">
				{requiredText}
			</span>}
			<span id="helptext" class="mc-field__help">
				{helperText}
			</span>

			<select id="foo" class="mc-field__input mc-select" {...props}>
				<option value="" selected disabled>{placeholder ? placeholder : "—Escolha uma opcão—"}</option>
				{options.map(o =>
					<option value={o.value}>{o.label}</option>
				)}
			</select>
		</div>
	);
};





export default Select;
