import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ArrowArrowBottom32 } from "@mozaic-ds/icons/react/index";

import "./QuantityPicker.css";
import "./overide.css";

import { useShoppingCart } from "../../Context/ShoppingCartContext";

const prefix = "mc-QuantityPicker--";

const QuantityPicker = ({
  children,
  bordered,
  variant,
  size,
  full,
  className,
  Icon,
  iconRight,
  valid,
  invalid,
  qtde = 0,
  index = 0,
  ...props
}) => {
  const [value, setValue] = useState(qtde);
  const [variantClass, setVariantClass] = useState("");
  const [sizeClass, setSizeClass] = useState("");
  const SContext = useShoppingCart();

  useEffect(() => {
    const variantClass = `${prefix}${bordered ? "bordered" : "solid"}${
      variant && variant !== "primary" ? `-${variant}` : ""
    }`;
    setVariantClass(variantClass);
  }, [bordered, variant]);

  useEffect(() => {
    const sizeClass = `${prefix}${size ? size : "m"}`;
    setSizeClass(sizeClass);
  }, [size]);

  const icon = Icon ? (
    <Icon className="mc-QuantityPicker__icon  mc-QuantityPicker__icon--m" />
  ) : null;
  const isIconQuantityPicker = Icon && !children;

  const handleIncrement = () => {
    setValue(value + 1);
    SContext.ShoppingCart.list[index].quantity = value + 1;
  };

  const handleDecrement = () => {
    if (value > 1) {
      setValue(value - 1);
      SContext.ShoppingCart.list[index].quantity = value - 1;
    }
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue > 0) {
      setValue(newValue);
      SContext.ShoppingCart.list[index].quantity = newValue;
    }
  };

  return (
	<div className="quantityPicker">

      <div className="mc-quantity-selector">
        <button
          className="mc-button mc-button--square mc-button--bordered mc-quantity-selector__button-left"
          aria-controls="qty-selector"
          aria-label="Decrement"
          onClick={handleDecrement}
        >
          <span
            className="mc-button__icon"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "1.5rem",
            }}
          >
            -
          </span>{" "}
        </button>
        <input
          id="qty-selector"
          className={classNames(
            "mc-text-input mc-quantity-selector__input",
            className
          )}
          value={value}
          onChange={handleChange}
          {...props}
          name="quantity-selector-input"
          aria-label="QuantitySelector"
          aria-valuemin={0}
          aria-valuemax={100}
          aria-valuenow={value}
          type="number"
          placeholder="0"
        />
        
        <button
          aria-controls="qty-selector"
          className="mc-button mc-button--square mc-button--bordered mc-quantity-selector__button-right"
          aria-label="Increment"
          onClick={handleIncrement}
        >
          <span
            className="mc-button__icon"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "1.5rem",
            }}
          >
            +
          </span>
        </button>
      </div>
    </div>

  );
};

QuantityPicker.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  bordered: PropTypes.bool,
  full: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  Icon: PropTypes.func,
  iconRight: PropTypes.bool,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
};

QuantityPicker.defaultProps = {
  children: null,
  bordered: false,
  full: false,
  variant: "",
  className: "",
  size: "",
  Icon: null,
  iconRight: false,
  valid: true,
  invalid: false,
};

export default QuantityPicker;
