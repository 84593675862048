import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import "./Modal.css";
import Button from "../Button";
import "./overide.css";

import { useShoppingCart } from "../../Context/ShoppingCartContext";

const Modal = ({
  text = "Histórico",
  cancel,
  isHidden,
  confirm,
  title,
  subtitle,
  children,
  disabled,
  option,
  onClick = () => {},
  onClose = () => {},
  onConfirm = () => {},
  ...props
}) => {
  const [hidden, setHidden] = useState(isHidden);
  const SContext = useShoppingCart();

  useEffect(() => {
    setHidden(isHidden);
  }, [isHidden]);

  return (
    <>
      <div
        class="mc-modal"
        style={{ display: hidden ? "none" : "" }}
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-hidden="true"
      >
        <div class="mc-modal__dialog is-open" role="document">
          <header class="mc-modal__header">
            <h2 class="mc-modal__title" id="modalTitle">
              {title}
            </h2>

            <button
              class="mc-modal__close"
              onClick={() => {
                onClose();
                SContext.updateShoppingCartState({
                  ...SContext.ShoppingCart,
                  list: [...SContext.ShoppingCart.list],
                });
              }}
              type="button"
            >
              <span class="mc-modal__close-text">Close</span>
            </button>
          </header>
          <main class="mc-modal__body">
            <article class="mc-modal__content">
              {subtitle !== "" && <h3 class="mc-modal__heading">{subtitle}</h3>}
              <div class="mc-modal__inner mt-body-m">{children}</div>
            </article>
          </main>
          <footer
            class="mc-modal__footer"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              flexDirection: "row",
            }}
          >
            {option !== "" && (
              <Button
                variant={"tertiary"}
                type="button"
                className="mc-button-pop-up"
              >
                {option}
              </Button>
            )}
            {cancel !== "" && (
              <Button
                onClick={() => {
                  onClose();
                }}
                variant={"secondary"}
                type="button"
                className="mc-button-pop-up"
              >
                {cancel}
              </Button>
            )}
            {confirm !== "" && (
              <div>
                {confirm !== "GUARDAR ALTERAÇÕES" && (
                  <Button
                    onClick={() => {
                      onConfirm();
                    }}
                    type="button"
                    className="mc-button-pop-up"
                    disabled={disabled}
                  >
                    {confirm}
                  </Button>
                )}

                {confirm === "GUARDAR ALTERAÇÕES" && (
                  <Button
                    type="button"
                    onClick={() => {
                      onClose();
                      SContext.updateShoppingCartState({
                        ...SContext.ShoppingCart,
                        list: [...SContext.ShoppingCart.list],
                      });
                    }}
                    className="mc-button-pop-up-alternative"
                  >
                    {confirm}
                  </Button>
                )}
              </div>
            )}
          </footer>
        </div>
      </div>
      <div
        onClick={(e) => {
          e.preventDefault()
          // Prevent the click event from propagating further
          e.stopPropagation();
        }}
        style={{
          display: hidden ? "none" : "",
          
          zIndex: 999,
         'pointer-events': 'none'
        }}
        class="mc-modal-overlay is-visible"
      
      ></div>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  confirm: PropTypes.string,
  cancel: PropTypes.string,
  option: PropTypes.string,
  title: PropTypes.string,
  isHidden: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  confirm: "",
  cancel: "",
  title: "",
  subtitle: "",
  option: "",
  isHidden: true,
};

export default Modal;
