import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

const INITIALCONTEXT = {
   
};
    

const LoadingContext = createContext(
    INITIALCONTEXT
);

function LoadingContextProvider({ children }) {
    const [isLoading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider
          value={{
            isLoading,
            setLoading      
          }}
        >
          {children}
        </LoadingContext.Provider>
      );
}
    
function useLoadingSpinner() {
    const context = useContext(LoadingContext);
    
    if (!context) {
    throw new Error(
        'useLoadingSpinner must be used within an LoadingContextProvider'
    );
    } 
    return context;
}
    
export { LoadingContextProvider, useLoadingSpinner };