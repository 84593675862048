import React, { useState } from "react";
import Modal from "./../../components/Modal";
import "./ModalPrice.css";
import Badge from "../../components/Badge";
import TextInput from "./../../components/TextInput";
import Select from "./../../components/Select";
import { useShoppingCart } from "../../Context/ShoppingCartContext";

export default function ModalPrice({ isHidden, data }) {
  const [price, setPrice] = useState("");
  const [motivo, setMotivo] = useState(null);
  const [Disabled, setDisabled] = useState(true);
  const {addDiscount} = useShoppingCart()
  const [product, setProduct] = useState(
    data || {
      name: "Nome do Produto",
      price: 0.0,
      maxProduct: "1000",
      quantity: 0,
      sku: "123456789",
    }
  );

  const handleInputChange = (e) => {
    // Remove todos os pontos decimais extras da entrada
    const inputValue = e.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
  
    // Define o valor formatado no estado
    setPrice(inputValue);
  
    // Verifica se o motivo e o valor formatado estão preenchidos
    if (!motivo || !inputValue) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const handleSaveDiscount =()=>{
  

   addDiscount(motivo,price,product.id)
  
  }
  const modalContent = () => {
    return (
      <div className="modal-price-container">
        <h3 className="modal-price-title">Preço atual</h3>
        <p className="modal-price-value">
          {parseFloat(product.price / 100).toFixed(2) + " €" || "0.00 €"}
        </p>
        <div className="modal-price-form-container">
          <p className="modal-price-label">Novo preço <span style={{fontSize:9}}>- Obrigatório</span></p>
          <TextInput
            value={price}
            className={"modal-price-input"}
   style={{"text-align": "unset"}}
            placeHolder={"Novo Preço"}
            onChange={(e) => handleInputChange(e)}
          ></TextInput>
          <p className="modal-price-label">Motivo <span style={{fontSize:9}}>- Obrigatório</span></p>
          <Select
         onChange={(e) =>{ 
          
          setMotivo(e.target.value)
        
          if(!e.target.value || !price){
            setDisabled(true)
            return
          }else{
            setDisabled(false)
          }
        
        }}
            options={[
              {
                value: 6,
                label: "Quantidade com Acumulação",
              },
              {
                value: 7,
                label: "Quantidade sem Acumulação",
              },
              { value: 8, label: "Erro Balizagem" },
              { value: 9, label: "Saldo Pontual" },
              { value: 10, label: "Dif. PVP Encomenda" },
            ]}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        children={modalContent()}
        confirm={"OK"}
        onConfirm={handleSaveDiscount}
        isHidden={isHidden}
        disabled={Disabled}
      ></Modal>
    </>
  );
}
