import React from "react";
import { ReactComponent as Cart } from "../../Assets/Icons/Standard.svg"
import { ReactComponent as AddCart } from "../../Assets/Icons/addCart.svg"
import PropTypes from "prop-types";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
import "./ProductItemSearch.css";
import "./overide.css"

const ProductItemSearch = ({ item,index, onClic = () => { return } }) => {
  const SContext = useShoppingCart();
  return (
    <>       
 <div style={{backgroundColor:"#666666",width:"100vw",height:1,zIndex:999,right:0  }}> </div>

      <div className={"product-item-search"} onClick={() => { 
        onClic();
        SContext.addScannedProduct(item.object) 
        }}>
        <div style={{ display: "flex", flexDirection: "row" ,    'justify-content':"space-evenly"}}>
          <div className="product-item-search__image" style={{  }}>
            <img src={item.image} alt="Product Image" />
          </div> 
          <div
            className="product-item-search__details"
            style={{ width: "70vw", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
          >
            <div className="product-item-search__header">
              <h3 className="product-item-search__title">{item.title}</h3>

              <div style={{ display: "flex", flexDirection: "row", }}>
                <p className="product-item-search__date">{item.date} €</p>
                <p className="product-item-search__time">{item.time}</p>
              </div>
            </div>
            <p className="product-item-search__price">{item.price} €</p>
            <button className="product-item-search-button" onClick={() => {
              console.log(item.object);
              
              

          }
            
            }>
              <AddCart fill="#ffffff"></AddCart>
            </button>
          </div>
        </div>
      </div>

    </>
  );

};

ProductItemSearch.propTypes = {
  item: PropTypes.shape({
    object: PropTypes.object,
    image: PropTypes.string,
    sku: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    quantity: PropTypes.string,
    status: PropTypes.string
  }),
};

export default ProductItemSearch;
