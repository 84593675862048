import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";
import { useLocalStorage } from "./useLocalStorage";
import { APICall } from "../API";

const INITIALCONTEXT = {
  name: "Matilde",
  img: null,
  storeId: 2,
  terminalId: 2,
  urlApi: "https://compra-agil.d.xkw.in",
  matricula: "",
  authData: false,
};

const UserContext = createContext(INITIALCONTEXT);

function UserContextProvider({ children }) {
  const [User, updateUser] = useLocalStorage("user", INITIALCONTEXT);

  const saveUserData = (userData) => {
    updateUser({ ...User, authData: userData });
  };
  const logout = () => {
    APICall.logout();
    updateUser({ ...User, authData: false });
  };

  useEffect(() => {
    APICall.getUserInfo().then((res) => {
      if (res.data && !Array.isArray( res.data)) {
        console.log("update user ");
        console.log(res.data );
        updateUser({
          ...User,
          authData: res.data,
        });
      } else {
        console.log("update user ");

        console.log(false);

        updateUser({
          ...User,
          authData: false,
        });
      }
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        User,
        updateUser,
        saveUserData,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within an UserContextProvider");
  }
  return context;
}

export { UserContextProvider, useUser };
