import React, {useEffect} from "react";
import Card from "./../../components/Card";
import { ReactComponent as Car } from "./../../Assets/Icons/directions_car.svg";
import "./MenuScreen.css";
import { useNavigate } from "react-router-dom";
import { useHistory } from "../../Context/HistoryContext";

export default function MenuScreen() {
  const navigate = useNavigate();
  const {History} = useHistory()

  useEffect(() => {
    console.log(History);
  }, [])

  return (
    <>
      <div className="menu-screen-container">
        <Card
          text="Nova Venda"
          Icon={() => <Car fill="#188703"></Car>}
          onClick={() => {
            navigate("/licence-plate");
          }}
        ></Card>
        <Card
         onClick={() => {
          navigate("/history");
        }}
        ></Card>
      </div>
    </>
  );
}
