
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { APICall } from '../API';

const checkInLifeExpectancy = 9000000;
const extraCheckInLifeExpectancy = 3600000;
const INITIALCONTEXT = {
        "list": [],  
    };

const ShoppingCartContext = createContext(
  INITIALCONTEXT,
);
function ShoppingCartProvider({ children }) {
  const [ShoppingCart, updateShoppingCartState] = useState(INITIALCONTEXT);
  const [canShowEditCart, setcanShowEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const addScannedProduct = async (object) => {
    const resultProduct = object;
    console.log(resultProduct);

    if (resultProduct) {
      console.log("ENTROU");
      

      if (resultProduct.maxQuantity > 0) {
        const tmpshp = ShoppingCart;

        const productIndex = tmpshp.list.findIndex(
          (product) => product.sku === resultProduct.sku
        );
        if (productIndex > -1) {
          if (
            tmpshp.list[productIndex].quantity + 1 >
            resultProduct.maxQuantity
          ) {
            return console.log('max quantity');
          }
          if (
            tmpshp.list[productIndex].quantity + 1 >
            resultProduct.maxQuantity
          ) {
            return console.log('max quantity');
          }
        }
      }
    }

    if (resultProduct) {
      const product = resultProduct;

      const tempShopping = ShoppingCart;

      // ShoppingCart.shoppingCart.products
      // check if product is already in cart by barcode and add or inccrease quantity
      const productIndex = tempShopping.list.findIndex(
        (product) => product.sku === resultProduct.sku
      );
      if (productIndex > -1) {
          tempShopping.list[productIndex].quantity =
          tempShopping.list[productIndex].quantity + 1;

        product.quantity =
          tempShopping.list[productIndex].quantity;

        


      } else {
        tempShopping.list.push(product);
      }

      updateShoppingCartState(tempShopping);
      setIsOpen(false);
      return product;
    }
  };

  const removeItem = (index) => {

    const tempShopping = { ...ShoppingCart }; // Create a shallow copy of ShoppingCart
    tempShopping.list.splice(index, 1);
    updateShoppingCartState(tempShopping);
  };
  
const addDiscount = (motivo, preco, id) => {
  const tempShopping = { ...ShoppingCart }; // Create a shallow copy of ShoppingCart

  // Find the product in the shopping cart by ID
  const productIndex = tempShopping.list.findIndex((product) => product.id === id);

  if (productIndex > -1) {
    // Apply the discount to the product

    tempShopping.list[productIndex].discountReason = motivo;

   let discount = tempShopping.list[productIndex].price - (preco*100)
   tempShopping.list[productIndex].discount = discount;
   tempShopping.list[productIndex].forcedDiscount =true
    // Update the shopping cart state
    updateShoppingCartState(tempShopping);
  } else {
    console.log("Product not found in the shopping cart.");
  }
};

//make the same for comments 
const addComment = (comment, id) => {
  const tempShopping = { ...ShoppingCart }; // Create a shallow copy of ShoppingCart

  console.log("comment", comment)
  console.log("id", id)
  //console log the find index of the product
  //make a for printing the product id of all the products in the cart
  // Find the product in the shopping cart by ID
  for(let i = 0; i < tempShopping.list.length; i++){
    console.log("tempShopping.list[i].id", tempShopping.list[i].id)
  }

  const productIndex = tempShopping.list.findIndex((product) => product.id === id);

  console.log("productIndex", productIndex)

  if (productIndex > -1) {
    // Apply the discount to the product
    tempShopping.list[productIndex].comments = comment;

    // Update the shopping cart state
    updateShoppingCartState(tempShopping);
  }
  else {
    console.log("Product not found in the shopping cart.");
  }
}



  return (
    <ShoppingCartContext.Provider
      value={{
        ShoppingCart,
        updateShoppingCartState,
        isOpen,
        setIsOpen,
        addScannedProduct,
        removeItem,
        addDiscount,
        addComment
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}

function useShoppingCart() {
  const context = useContext(ShoppingCartContext);

  if (!context) {
    throw new Error(
      'useShoppingCart must be used within an ShoppingCartProvider'
    );
  }

  return context;
}

export { ShoppingCartProvider, useShoppingCart };
