import React from 'react';

import './loader.css'


const Loader = ({ variant, ...props }) => {

	const variants = {
		light: " mc-loader--light",
		green: "",
		dark: " mc-loader--dark"
	}
	return (
		<div class={"mc-loader mc-loader--l mc-loader--l" + variants[variant]}>
			<span class="mc-loader__spinner">
				<svg
					class="mc-loader__icon"

					viewBox="0 0 64 64"
				>
					<circle class="mc-loader__path" cx="50%" cy="50%" r="19" />
				</svg>
			</span>
		</div>

	);
};

Loader.propTypes = {
	variant: 'light' | 'green' | 'dark'
};

Loader.defaultProps = {
	variant: "green"
};

export default Loader;
