import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import "./ModalComment.css";
import Button from "../Button";
import "./overide.css";
import { APICall } from "../../API";
import { useShoppingCart } from "../../Context/ShoppingCartContext";


const Modal = ({
  text = "Histórico",
  cancel,
  isHidden,
  confirm,
  title,
  subtitle,
  children,
  option,
  comment,
  sku,
  id,
  onClick = () => { },
  onClose = () => { },
  ...props
}) => {

  const [hidden, setHidden] = useState(isHidden)
  const {addComment} = useShoppingCart()

  useEffect(() => {
    setHidden(isHidden)
  
  }, [isHidden])

  return (
    <>

      <div
        class="mc-modal"
        style={{ display: hidden ? "none" : "" }}
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-hidden="true"
      >
        <div class="mc-modal__dialog is-open" role="document">
          <header class="mc-modal__header">
            <h2 class="mc-modal__title" id="modalTitle">{title}</h2>


            <button class="mc-modal__close" onClick={() => {onClose()}} type="button">
              <span class="mc-modal__close-text">Close</span>
            </button>
          </header>
          <main class="mc-modal__body">
            <article class="mc-modal__content">
              {subtitle !== "" && <h3 class="mc-modal__heading">{subtitle}</h3>}
              <div class="mc-modal__inner mt-body-m" >
                {children}
              </div>
            </article>
          </main>
          <footer class="mc-modal__footer" style={{
            alignItems: "center",
            alignSelf: "center",
            width: "100%",
            flexDirection: "column",
          }}>
            <div>
              {option !== "" &&
                <Button  variant={"tertiary"} type="button" className="mc-button-pop-up">
                  {option}
                </Button>}
            </div>
            <div style={{marginBottom:"1rem"}}>
              {cancel !== "" &&
                <Button onClick={() => { onClose() }} variant={"secondary"} type="button" className="mc-button-pop-up">
                  {cancel}
                </Button>}
            </div>
            <div>
              {confirm !== "" &&
                <Button type="button" onClick={() => {
                      const data = {
                          comment: comment,    
                        };
  
                      console.log("clicked")
                      addComment(comment, id)
                    
                      APICall.addComment(sku, data).then((res) => {
                          console.log(res.data)
                          onClose()
                      }
                      ).catch((err) => {
                          console.log(err)
                      })
                      onClose()
                  }
               } className="mc-button-pop-up">
                  {confirm}
                </Button>}
            </div>
          </footer>
        </div>
      </div>
      <div 
        onClick={(e) => {
          // Prevent the click event from propagating further
          e.stopPropagation();
        }}
        style={{ display: hidden ? "none" : "" }} class="mc-modal-overlay is-visible" tabindex="-1" role="dialog"></div>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  confirm: PropTypes.string,
  cancel: PropTypes.string,
  option:PropTypes.string,
  title: PropTypes.string,
  isHidden: PropTypes.bool
};

Modal.defaultProps = {
  children: null,
  confirm: "",
  cancel: "",
  title: "",
  subtitle: "",
  option:"",
  isHidden: true
};

export default Modal;
