import React, { useState, useEffect } from "react";
import Modal from "../../../components/ModalComment";
import "./ModalFicha.css";
import QRCode from "react-qr-code";
import { APICall } from "../../../API";

export default function ModalFicha({ isHidden, onClose, info, id }) {
  const [qrCodeData, setQrCodeData] = useState(null);

  useEffect(() => {
    if (!isHidden) {
      fetchQrCode();
    }
  }, [isHidden]);

  const fetchQrCode = async () => {
    try {
      const response = await APICall.getQrCodeForProduct(id);
      setQrCodeData(response); // assuming the API response contains qrCode data
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  const modalContent = () => {
    return (
      <div className="modal-add-qrcode">
        <h3>Ficha de Produto</h3>
        <p>Leia o QR Code para ter acesso à Ficha de Produto.</p>
        {qrCodeData && (
          <img
            style={{ width: 200, height: 200 }}
            src={qrCodeData}
            alt="QR Code"
          />
        )}
      </div>
    );
  };

  return (
    <Modal onClose={() => onClose()} children={modalContent()} isHidden={isHidden} />
  );
}
