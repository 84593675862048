import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalDeleteProduct from "../../Screens/Modal/ModalDeleteProduct";
import "./ProductItem.css";
import "./overide.css";
import ModalProduct from "../../Screens/Modal/ModalProduct";
import { useNavigate } from "react-router-dom";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
import SwipeableListItem from "./SwipeableListItem";

const ProductItem = ({
  disabled = false,
  item,
  isHistoryScreen,
  onClick = () => {
    return;
  },
  index,
  disableDelete,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const [dado, setDado] = useState(0);
  const [hideDelete,setHideDelete ] = useState(true);
  const SContext = useShoppingCart();

  const getStatusColor = {
    pending: "#C61112",
    finished: "#C5E39E",
    canceled: "#b85922",
  };

  const navigate = useNavigate();

  const navigateTo = () => {
    console.log(item)
    switch (item.status) {
      case "finished":
        navigate("/history-products/" + item.id);
        break;
      case "canceled":
        navigate("/pending-cart/" + item.id + "?canceled=true");
        break;
      case "pending":
        navigate("/pending-cart/" + item.id, { replace: true });
        break;
      default:
        !disableDelete && setIsHidden(false);
    }
  };

  return (
    <>
      <ModalProduct
        onClose={() => {
          setIsHidden(true);
          setDado(0);
        }}
        isHidden={isHidden}
        produto={item.object}
        index={item.index}
      ></ModalProduct>
        <ModalDeleteProduct
        onConfirm={() => {
          setHideDelete(false);
          SContext.removeItem(index);
          SContext.updateShoppingCartState({
            ...SContext.ShoppingCart,
            list: [...SContext.ShoppingCart.list],
          });
        }}
        isHidden={hideDelete}
        onClose={setHideDelete}
      ></ModalDeleteProduct>
      <div
        className={"product-item"}
        onPointerLeaveCapture={() => {
          if (!disabled) {
            navigateTo(item.status);
          }
        }}
      >
        <SwipeableListItem
        onPointerLeaveCapture={() => {
          navigateTo(item.status);
        }}
        isHistoryScreen={isHistoryScreen}
          index={index}
          disableDelete={disableDelete}
          item={item}
          disabled={disabled}
          setHideDelete={setHideDelete}
        />
      </div>
    </>
  );
};

ProductItem.propTypes = {
  item: PropTypes.shape({
    index: PropTypes.number,
    object: PropTypes.object,
    sku: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    quantity: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default ProductItem;
