import React, { useState } from "react";
import Modal from "../../../components/ModalComment";
import "./ModalAddComment.css"
import { APICall } from "../../../API";

export default function ModalAddComment({ isHidden, onClose, info, sku, id }) {
    const [comment, setComment] = useState(info)

    const modalContent = () => {
        return (
            <div className="modal-add-comment-container">
                <p className="modal-add-comment-title">Comentários</p>
                <textarea autoFocus style={{ padding: 20 }} className="comment-area" rows={12} cols={50} placeholder="Adiciona o teu comentário" value={comment} onChange={(info) => setComment(info.target.value)}/>
            </div>
        )
    }

    return (
        <>
            <Modal onClose={()=> onClose()} children={modalContent()} cancel={"CANCELAR"} confirm={!info ? "GUARDAR" : "EDITAR"} isHidden={isHidden} comment={comment} sku={sku} id={id}></Modal>
        </>
    );
}
