import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { APICall } from '../API';
import { useUser } from "./userContext";

const INITIALCONTEXT = {
  "list": [],  
};


const HistoryContext = createContext(
  INITIALCONTEXT,
);

export const HistoryProvider = ({ children }) => {
  const [History, setHistory] = useLocalStorage("History", []);
  const User = useUser();
  useEffect(() => {
  
  }, []);

  const addHistory = async () => 
    
    APICall.salesByDate(User.User.storeId, "today")
      .then((res) => res)
      .catch((err) => err);
    

  const addToHistory = async () => {
    const tempHst = History;
    await addHistory().then((temp) => {
    console.log(temp.data);
    //convert temp.data.sale_data from string to object
    for (let i = 0; i < temp.data?.length; i++) {
      temp.data[i].sale_data = JSON.parse(temp.data[i].sale_data);
    }

    setHistory(temp.data);
    console.log(History);
    }); 
  


  };

  const value = useMemo(
    () => ({
      History,
      setHistory,
      addToHistory,
      addHistory
    }),
    [History]
  );
  return (
    <HistoryContext.Provider value={value}>
      {children}

    
    </HistoryContext.Provider>
  );
};

export const useHistory = () => {
  return useContext(HistoryContext);
};

