import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import ProductItemSearch from "../ProductItemSearch";
import "./Dropdown.css";
import Search from "../../Assets/Icons/Search.png";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
import { APICall } from "../../API";
import { useUser } from "../../Context/userContext";
import { useLoadingSpinner } from "../../Context/LoadingContext";
import logo from "./../../Assets/logo.png";
import Button from "../Button";
let keybuffer = "";
let resultListBuffer = [];
const SearchDropdown = ({
  options,
  searchTerm,
  handleSearchChange,
  isOpen,

  setSearchTerm,
}) => {
  const fuse = new Fuse(options, { keys: ["title"] });
  const [searchTer, setSearchTer] = useState("");
  const SContext = useShoppingCart();
  const User = useUser();
  const [result, setResult] = useState([]);
  const { setLoading } = useLoadingSpinner();

  function alertKeyCode(e) {
    if (e.keyCode >= 48 && e.keyCode <= 57) {
      // teclas normais
      let num = e.keyCode - 48;
      keybuffer += num.toString();
    }

    if (e.keyCode == 13) {
      // Enter
      setLoading(true);
      APICall.searchProducts(User.User.storeId, keybuffer)
        .then((res) => {
          console.log(res.data);

          resultListBuffer = res.data.list;
        })
        .catch((err) => {
          console.log(err);
          resultListBuffer = [];
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });

      keybuffer = "";
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", alertKeyCode);

    return () => {
      window.removeEventListener("keydown", alertKeyCode);
    };
  }, []);

  useEffect(() => {
    if (resultListBuffer.length > 0) {
      SContext.setIsOpen(true);
      setResult(resultListBuffer);

      resultListBuffer = [];
    }
  }, [resultListBuffer]);

  const handleSearchChanges = (event) => {
    setSearchTer(event.target.value);
    if (event.target.value !== "") {
      SContext.setIsOpen(true);
    } else {
      SContext.setIsOpen(false);
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      console.log(searchTer);
      setLoading(true);
      //setSearchTer("")
      APICall.searchProducts(User.User.storeId, searchTer)
        .then((res) => {
          console.log(res.data);
          setResult(res.data.list);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      <div
        className="search-dropdown"
        style={{ overflowY: "auto", overflowX: "clip" }}
      >
        <div
          className="search-bar"
          style={{ display: "flex", alignItems: "center" }}
        >
          <input
            type="search"
            placeholder="Procurar"
            value={searchTer}
            onChange={handleSearchChanges}
            onKeyDown={handleEnterPress}
            className="mc-left-icon-input__input mc-text-input mc-field__input"
            style={{ marginRight: "10px" }}
          />
          <Button
            style={{ marginTop: "5px", width: 48, height: 48 }}
            onClick={() => {
              setLoading(true);
              //setSearchTer('');
              APICall.searchProducts(User.User.storeId, searchTer)
                .then((res) => {
                  console.log(res.data);
                  setResult(res.data.list);
                })
                .catch((err) => {
                  console.log(err);
                  setResult([]);
                  setLoading(false);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
            className={"cart-button cart-button-80"}
          >
            <img src={Search} style={{ width: 28, height: 28 }}></img>
          </Button>
        </div>

        {isOpen && (
          <div className={"div-input"} style={{ marginTop: 20 }}>
            {result.map((item, index) => (
              <>
                <ProductItemSearch
                  index={index}
                  key={index}
                  onClic={() => {
                    {
                      setSearchTer("");
                      SContext.setIsOpen(false);
                      setResult([]);
                    }
                  }}
                  item={{
                    object: item,
                    image: item.img || logo,
                    sku: item.sku,
                    title: item.name,
                    price: parseFloat(item.price / 100 || 0).toFixed(2),
                    date: parseFloat(item.price / 100 || 0).toFixed(2),
                    quantity: item.quantity,
                  }}
                />
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchDropdown;
