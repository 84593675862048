import {
  serverCallDelete,
  serverCallGet,
  serverCallPost,
  serverCallURL,
  serverCallPut,
  serverCallUpload,
} from "./serverCall";

export const APICall = {
  getStaticFile: (id) => {
    return serverCallURL("image/" + id);
  },
  getQrCodeForProduct: (id) => {
    return serverCallURL("/api/pre-order/product/sku/" + id+"/qrcode");
  },
  examplePostRequest: (data, id) => {
    return serverCallPost(`/api/${id}`, data);
  },
 
  exampleDeleteRequest: ( data) => {
    return serverCallDelete(`/delete/${data}`);
  },
  exampleGetRequest: (id) => {
    return serverCallGet("/api/usuarios/");
  },
  searchProducts: (id, nome) => {
    return serverCallGet("/api/pre-order/location/"+ id +"/search/" + nome);
  },
  searchScanProducts: (location, id) => {
    return serverCallGet("/api/pre-order/location/"+ location +"/product/" + id);
  },
  searchSku: (location, id) => {
    return serverCallGet("/api/pre-order/location/"+ location +"/product/sku/" + id);
  },
  stockBySku: (location, id) => {
    return serverCallGet("/api/pre-order/location/"+ location +"/product/sku/" + id+"/stock");
  },
  salesByDate: (location, date) => {
    return serverCallGet("/api/pre-order/location/"+ location +"/orders/" + date);
  },
  newSale: (location, data) => {
    return serverCallPost("/api/pre-order/location/"+ location +"/order/", data);
  },
  getSale: (location, id) => {
    return serverCallGet("/api/pre-order/location/"+ location +"/order/" + id);
  },
  getSalePayment: (location, id) => {
    return serverCallGet("/api/pre-order/location/"+ location +"/order/" + id);
  },
  deleteSale: (location, id) => {
    return serverCallDelete("/api/pre-order/location/"+ location +"/order/" + id);
  },
  setSaleStatus: (location, id) => {
    return serverCallPut("/api/pre-order/location/"+ location +"/order/" + id + "/status");
  },

  getCancelas: (location) => {
    return serverCallGet("/api/lpr/location/"+location+"/cameras");
  },

  openCancela: (location,id) => {
    return serverCallGet("/api/lpr/location/"+location+"/open/" + id);
  },

  addComment: (data, comment) => {
    return serverCallUpload("/api/pre-order/product/sku/"+ data +"/comment", comment);
  },

  examplePutRequest: (id, data) => {
    return serverCallPut(`/api/${id}`, data);
  },
  exampleFilterRequest: (filtros) => {
    let urlWithFilter = "/api/";
    if (filtros) {
      urlWithFilter += "?";
      for (let key in filtros) {
        urlWithFilter += `${key}=${filtros[key]}&`;
      }
    }
    return serverCallGet(urlWithFilter);
  },
  getUserInfo: () => {
    return serverCallGet("/api/pingID/userInfo");
  },
  logout: () => {
    return serverCallGet("/api/pingID/logout");
  },
  getLoginUrl: () => {
    return serverCallURL("/api/pingID/login?on_success="+window.location.origin+"/app/menu");
  },
 
};
