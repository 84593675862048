import React, { useEffect, useState } from "react";
import LicencePlateInput from "../../components/LicencePlateInput";
import Button from "../../components/Button";
import { ReactComponent as Cart } from "../../Assets/Icons/Standard.svg";
import { ReactComponent as Voice } from "../../Assets/Icons/keyboard_voice.svg";
import { ReactComponent as BookStore } from "../../Assets/Icons/BookStore.svg";
import { ArrowArrowBottom32 } from "@mozaic-ds/icons/react/index";
import { ReactComponent as HistoryIcon } from "../../Assets/Icons/history.svg";
import logo from  "./../../Assets/logo.png";
import "./HistoryScreen.css";
import ProductItem from "../../components/ProductItem";
import TextInput from "../../components/TextInput";
import Tabs from "../../components/Tabs";
import ModalProduct from "../Modal/ModalProduct";
import { useHistory } from "../../Context/HistoryContext";
export default function HistoryScreen() {
  const [openTab, setOpenTab] = useState(0);
  const [query, setQuery] = useState("");
  const { History, addToHistory } = useHistory();

  useEffect(() => {
    addToHistory();
  }, []);

  const ItemsCart = () => {
    var filteredHistory = History?.filter((item) => {
      if (query == "") {
        // If the query is empty, show all items
        return true;
      }
      // Customize the condition to filter items based on all fields.
      // In this example, we check if any field contains the query (case-insensitive).
      return (
        JSON.stringify(item.order_ref)
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        // item.field.toLowerCase().includes(query.toLowerCase()) ||
        // ...
        false
      );
    });
    filteredHistory?.sort((a, b) => {
      const dateA = new Date(a.creation_date);
      const dateB = new Date(b.creation_date);

      // Compare dates in descending order
      return dateB - dateA;
    });

    return (
      <div style={{ overflowY: "hidden", paddingTop: 20 }}>
        {filteredHistory?.length === 0 || !filteredHistory ? (
          <div className="stateless">
            <p>Não existe nenhum Histórico!</p>
            <BookStore fill="#188803" className="cart-stateless"></BookStore>
          </div>
        ) : (
          filteredHistory?.map((item, index) => (
            <>
              <ProductItem
                key={index}
                index={index} // Make sure to provide a unique key when mapping through a list
                disableDelete={true}
                isHistoryScreen={true}
                item={{
                  id: item.id,
                  image: logo,
                  title: item.order_ref,
                  price: parseFloat(item.amount / 100).toFixed(2),
                  date: (() => {
                    // Format the date from "YYYY-MM-DD" to "DD/MM/YYYY"
                    const dateParts = item.creation_date
                      .split(" ")[0]
                      .split("-");
                    return (
                      dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0]
                    );
                  })(),
                  time: (() => {
                    // Format the time from "HH:MM:SS" to "HH:MM"
                    const timeParts = item.creation_date
                      .split(" ")[1]
                      .split(":");
                    return timeParts[0] + ":" + timeParts[1] + "h";
                  })(),
                  quantity: "",
                  status: item.sales_status_id === 3 ? "pending" : "finished",
                }}
              />
              {/* <ProductItem
              key={index} 
              index={index}// Make sure to provide a unique key when mapping through a list
              disableDelete={true}
              item={{
                image: "https://i.ibb.co/WkmYtDR/Group-150.png",
                title: item.payment_reference,
                price: parseFloat(item.amount / 100).toFixed(2),
                date: (() => {
                  // Format the date from "YYYY-MM-DD" to "DD/MM/YYYY"
                  const dateParts = item.creation_date.split(" ")[0].split("-");
                  return dateParts[2] + '/' + dateParts[1] + '/' + dateParts[0];
                })(),
                time: (() => {
                  // Format the time from "HH:MM:SS" to "HH:MM"
                  const timeParts = item.creation_date.split(" ")[1].split(":");
                  return timeParts[0] + ':' + timeParts[1] + 'h';
                })(),
                quantity: "",
                status: "finished"
              }}
            />
            <ProductItem
              key={index} 
              index={index}// Make sure to provide a unique key when mapping through a list
              disableDelete={true}
              item={{
                image: "https://i.ibb.co/WkmYtDR/Group-150.png",
                title: item.payment_reference,
                price: parseFloat(item.amount / 100).toFixed(2),
                date: (() => {
                  // Format the date from "YYYY-MM-DD" to "DD/MM/YYYY"
                  const dateParts = item.creation_date.split(" ")[0].split("-");
                  return dateParts[2] + '/' + dateParts[1] + '/' + dateParts[0];
                })(),
                time: (() => {
                  // Format the time from "HH:MM:SS" to "HH:MM"
                  const timeParts = item.creation_date.split(" ")[1].split(":");
                  return timeParts[0] + ':' + timeParts[1] + 'h';
                })(),
                quantity: "",
                status: "canceled"
              }}
            /> */}
            </>
          ))
        )}
      </div>
    );
  };

  return (
    <div className="mainHistory">
      <div className="HistoryTopDiv">
        <div className="SearchBar">
          <div className="SearchInputDiv">
            <div style={{ width: "100vw" }}>
              <input
                type="search"
                class="mc-left-icon-input__input mc-text-input mc-field__input"
                placeholder="Procurar por matrícula"
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>
          </div>
          <div className="divider_cart" style={{ marginTop: 10 }}></div>
        </div>
        <div style={{ marginTop: 1, width: "100vw" }}></div>
      </div>
      <ItemsCart></ItemsCart>
    </div>
  );
}
