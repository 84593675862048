import React, {useEffect} from "react";
import Card from "../../components/Card";
import { ReactComponent as Car } from "./../../Assets/Icons/directions_car.svg";
import {ReactComponent as Cloud } from "./../../Assets/Icons/cloud_done.svg";
import {ReactComponent as Next } from "./../../Assets/Icons/Next.svg";
import {ReactComponent as StandardCar } from "./../../Assets/Icons/StandardCar.svg";
import Button from "./../../components/Button";
import "./FinishScreen.css";
import { useNavigate } from "react-router-dom";
import { useHistory } from "../../Context/HistoryContext";

export default function MenuScreen() {
  const navigate = useNavigate();
  const {addToHistory} = useHistory()
  const handleFinish = () => {
    navigate("/menu");
  }

  useEffect(() => {
    addToHistory()
  },[])


  return (
    <>
      <div className="mainFinish">


        <div className="svg-container">
        <StandardCar fill="#188703"></StandardCar>
        <Next fill="#188703"></Next>
        <Cloud fill="#188703"></Cloud>
        </div>
        <p className="titleFinish">Carrinho exportado com sucesso!</p>
      </div>
      <Button className={"login-button"} onClick={handleFinish} full={true}>
        OK
      </Button>
    </>
  );
}
