import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as HamburgerMenu } from "../../Assets/Icons/hamburguer.svg";
import spinnerIndicator from "../../Assets/Indicator.png";
import "./LoadingSpinner.css";
import "./overide.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogOutIcon } from "../../Assets/Icons/LogOut.svg";
import { ReactComponent as DoubleBackArrow } from "../../Assets/Icons/DoubleArrowLeft.svg";
import { ReactComponent as CancelaIcon } from "../../Assets/Icons/cancela.svg";
import { useUser } from "../../Context/userContext";

import { ReactComponent as Setting } from "../../Assets/Icons/Setting.svg";
import ModalApiConfig from "../../Screens/Modal/ModalApiConfig";
import ModalOpenCancela from "../../Screens/Modal/ModalOpenCancela";
import Loader from "../Loader";
const LoadingSpinner = ({whitebackground=false}) => {
  const { User } = useUser();

  const menuRef = useRef(null);
  const navigate = useNavigate();

  return (
    <div className="loading-spinner" style={{backgroundColor:whitebackground?'white':""}}>
      <div className="loading-spinner__circle">
        <div class="mc-loader">
          <Loader></Loader>
        </div>
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  title: PropTypes.string.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default LoadingSpinner;
