import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./LicencePlateSimpleInput.css";
import "./overide.css"
import { useUser } from "../../Context/userContext";
const Input = ({ value, onChange, valid, invalid, className, ...props }) => {
  
  return (
    <input
      className={classNames(
        "rm-text-input mc-text-input",
        { "is-valid": valid, "is-invalid": invalid },
        className
      )}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

// const LeftIconInput = ({ className, ...props }) => {
//   const User = useUser();
//   const [inputValue, setInputValue] = useState(User.User.matricula);

//   const handleChange = (e) => {
//     const formattedValue = formatLicensePlate(e.target.value);
//     setInputValue(formattedValue);
//     User.updateUser(
//       {
//         ...User.User,
//         matricula: formattedValue,
//       }

//     );
//   };
 
//     const formatLicensePlate = (value) => {
//       // Remove any non-alphanumeric characters
//       const alphanumericValue = value.replace(/[^A-Za-z0-9]/g, "");
  
//       // Insert spaces between every two characters
//       const formattedValue = alphanumericValue
//         .slice(0, 6)
//         .replace(/(.{2})/g, "$1 ")
//         .trim(); // Trim any leading/trailing spaces
  
//       return formattedValue.toUpperCase();
//     };

    const LeftIconInput = ({ className, ...props }) => {
      const User = useUser();
      const [inputValue, setInputValue] = useState(User.User.natricula);
    
      const handleChange = (e) => {
            const formattedValue = formatLicensePlate(e.target.value);
            setInputValue(formattedValue);
            User.updateUser(
              {
                ...User.User,
                matricula: formattedValue,
              }
        
            );
          };
    
      const formatLicensePlate = (value) => {
       // Remove any non-alphanumeric characters
      const alphanumericValue = value.replace(/[^A-Za-z0-9]/g, "");
      // limit to 8 characters
      const formattedValue = alphanumericValue.slice(0, 8);

  
      return formattedValue.toUpperCase();
         
    };  

  return (
    <div className="LicencePlate">
   
      <div className="mc-left-icon-input">
        <Input
        className={"licence-responsive-width"}
          value={User.User.matricula}
          onChange={handleChange}
          placeHolder="00AA00"
          style={{
            boxShadow: "none",
            
            textAlign: "left",
            fontSize: "20px",
          }}
          maxLength={8}
          {...props}
        />
      </div>

    </div>
  );
};

const LicencePlateSimpleInput = ({ Icon, ...props }) => <LeftIconInput {...props} />;

Input.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Input.defaultProps = {
  value: "",
  className: "",
  valid: false,
  invalid: false,
};

LeftIconInput.propTypes = {
  Icon: PropTypes.func.isRequired,
  className: PropTypes.string,
};

LeftIconInput.defaultProps = {
  className: "",
};

export default LicencePlateSimpleInput;
