import React from "react";
import Modal from "./../../components/Modal";
import "./ModalOpenCancela.css"
import { useCancela } from "../../Context/CancelaContext";


export default function ModalOpenCancela({ isHidden,cancelaNumber,onClose }) {

    const {openCancelaFromMacAddress} = useCancela();
    const modalContent = () => {
        return (
            <div className="modal-cancela-product-container">
                <h3 className="modal-cancela-question">Tem a certeza que deseja  abrir a CANCELA {cancelaNumber?.lane}?</h3>
  
            </div>
        )
    }

    return (
        <>
            <Modal onConfirm={()=>{ openCancelaFromMacAddress(cancelaNumber?.mac_address); onClose()}} onClose={()=>onClose()} children={modalContent()} cancel={"NĀO"} confirm={"SIM"}  isHidden={isHidden} ></Modal>
        </>
    );
}
