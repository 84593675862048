import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  lazy,
  Suspense,
} from "react";
import "./useQRScanner.css";
import dataHandler from "../components/Video/dataHandler";

const Video = lazy(() => import("../components/Video"));
const Scanner = createContext({
  closeScanner: async () => {},
  openScanner: async () => {},
});

const ScannerProvider = ({ children }) => {
  // IMPLEMENTAÇÃO DO MODAL DE POPUP
  const [canShowScanner, setcanShowScanner] = useState(false);
  const openScanner = () => {
    setcanShowScanner(true);
  };
  const closeScanner = () => {
    setcanShowScanner(false);
  };

  // IMPLEMENTAÇÃO DA CÂMERA
  const [isCameraSupported, setCameraSupported] = useState(false);
  const [isCameraEnabled, setCameraEnabled] = useState(
    dataHandler.isCameraPermissionGranted()
  );

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      setCameraSupported(true);
    }
  }, []);

  const onCamEnabled = () => {
    dataHandler.cameraPermissionGranted();
    setCameraEnabled(true);
  };

  const onCancel = () => {
    closeScanner();
  };

  return (
    <Scanner.Provider
      value={{
        openScanner,
        closeScanner,
      }}
    >
      {canShowScanner && (
        <div className="modal__overlay">
          <div className="modal__content">
            <>
              {isCameraSupported && isCameraEnabled ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <Video />
                </Suspense>
              ) : (
                ""
              )}
              {isCameraSupported && !isCameraEnabled ? (
                <>
                  <div className="cameraHandler__message">
                    Ative a câmera com o botão abaixo
                    <br />
                    <div className="cameraHandler__messageIcon">AD</div>
                  </div>
                  <button
                    type="button"
                    aria-label="Ativar Câmera"
                    className="btn__round camera__enable"
                    onClick={onCamEnabled}
                  >
                    CLICK TO AUTORIZE CAMERA
                  </button>
                </>
              ) : (
                ""
              )}
              {!isCameraSupported ? (
                <div className="cameraHandler__unsopported">
                  <div>
                    <p>
                      Seu dispositivo não suporta acesso à câmera ou algo deu
                      errado.
                    </p>
                    <p>Você pode inserir o código de barras abaixo.</p>
                  </div>
                </div>
              ) : (
                ""
              )}
              <button
                type="button"
                className="cancel__button"
                onClick={onCancel}
              >
                Cancelar
              </button>
            </>
          </div>
        </div>
      )}
      {children}
    </Scanner.Provider>
  );
};

function useScanner() {
  const context = useContext(Scanner);

  if (!context) {
    throw new Error("useScanner deve ser usado dentro de um ScannerProvider");
  }

  return context;
}

export { ScannerProvider, useScanner };
