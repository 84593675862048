import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './passwordinput.css'

const Input = ({ value, onChange, valid, invalid, className, ...props }) => {
	const handleChange = (e) => {
		onChange(e.target.value, e);
	};
	const [hidden,setHidden]=useState(true);
	return (
		<div class="mc-password-input"> 
			<input
				className={classNames(
					'mc-text-input mc-password-input__control',
					{ 'is-valid': valid, 'is-invalid': invalid },
					className
				)}
				value={value}
				type={hidden?"password":"text"}
				onChange={handleChange}
				{...props}
			/>
			<button
				type="button"
				class="mc-password-input__button"
				role="switch"
				onClick={()=>setHidden(!hidden)}
			>
				{hidden?"Show":"Hide"}
			</button>
		</div>
	);
};


const PasswordInput  = ({ Icon, ...props }) => (  <Input {...props} />);

Input.propTypes = {
	value: PropTypes.string,
	className: PropTypes.string,
	valid: PropTypes.bool,
	invalid: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

Input.defaultProps = {
	value: '',
	className: '',
	valid: false,
	invalid: false,
};


PasswordInput.propTypes = {
	Icon: PropTypes.func,
};

PasswordInput.defaultProps = {
	Icon: null,
};

export default PasswordInput;
