import React from "react";
import TextInput from "./../../components/TextInput";
import Modal from "./../../components/Modal";
import "./ModalQrCode.css"
import QrCodeImg from "./../../Assets/qrCodeExample.png";
import Badge from "../../components/Badge";


export default function ModalQrCode({ isHidden }) {

    const modalContent = () => {
        return (
            <div className="modal-qr-container">
                <h1 className="api-qr-title">Ficha de Produto</h1>
                <p className="api-qr-subtitle">Leia o Qrcode para ter acesso á Ficha de Produto.</p>
                <div className="qr-image-container">
                    <img className="qr-image" src={QrCodeImg} />
                </div>
                { /*  <h1 className="api-qr-title">Link:</h1>
                <p className="api-qr-link">https://ijirufgbdiug122544edjihgdukkll</p>
                <div  className="api-qr-badge">
                    <Badge label={"Copiar"} type={"info"}></Badge>
        </div>*/}
            </div>
        )
    }

    return (
        <>
            <Modal children={modalContent()} isHidden={isHidden} ></Modal>
        </>
    );
}
