import React, { useEffect, useState } from "react";
import LicencePlateInput from "../../components/LicencePlateInput";
import Button from "../../components/Button";
import { ReactComponent as Cart } from "../../Assets/Icons/Standard.svg";
import { ReactComponent as Voice } from "../../Assets/Icons/keyboard_voice.svg";
import { ArrowArrowBottom32 } from "@mozaic-ds/icons/react/index";

import ProductPendingItem from "../../components/ProductPendingItem";
import TextInput from "../../components/TextInput";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./PendingCartScreen.css";
import { useScanner } from "../../Hooks/useQRScanner";
import ModalDeleteSale from "../Modal/ModalDeleteSale";

import { useShoppingCart } from "../../Context/ShoppingCartContext";
import { APICall } from "../../API";
import { useUser } from "../../Context/userContext";
import { useLoadingSpinner } from "../../Context/LoadingContext";
import { useHistory } from "../../Context/HistoryContext";

export default function PendingCartScreen() {
  const [searchParams] = useSearchParams();
  const [canceled, setCanceled] = useState(false);
  const [hideDelete, setHideDelete] = useState(true);
  const { History ,addToHistory} = useHistory();
  const { index } = useParams();
  const User = useUser();
  const { setLoading } = useLoadingSpinner()
  const navigate = useNavigate();

  const SContext = useShoppingCart();

  const [Item, setItem] = useState(History[History.findIndex(
    (purchase) => purchase.id+"" == index+""
  )]);

  useEffect(() => {
    setCanceled(searchParams.get("canceled") == "true" ? true : false);
  }, []);

  const ItemsCart = ({
    lista = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  }) => {
    return (
      <div style={{ overflowY: "hidden", paddingBottom: "130px" }}>
        {Item.sale_data.list.map((item, index) => (
          <>
            <ProductPendingItem
              disableDelete={true}
              disabled={canceled}
              item={{
                image: item.img || "https://i.ibb.co/WkmYtDR/Group-150.png",
                title: item.name,
                discount: parseFloat(item.discount / 100 || 0).toFixed(2),
                price: parseFloat(item.price / 100 || 0).toFixed(2),
                date: parseFloat(item.price / 100 || 0).toFixed(2),
                quantity: item.quantity,
              }}
            />
          </>
        ))}
      </div>
    );
  };
  const onConfirmDelete = () => {
    setHideDelete()
    setLoading(true);
    APICall.deleteSale(User.User.storeId, Item.id)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        addToHistory()
  
        navigate("/history",{ replace: true });
        
      });
  };

  return (
    <div className="mainCart">
      <ModalDeleteSale
        onConfirm={onConfirmDelete}
        isHidden={hideDelete}
        onClose={setHideDelete}
      ></ModalDeleteSale>

      <div style={{ height: 14 }}></div>
      <ItemsCart></ItemsCart>

      <div className="bottomCart">
        <div className="divider_cart"></div>
        <div className="quantity_price_div">
          <div className="cart_icon_div">
            <Cart fill="#3A3936"></Cart>
            <h4 className="cart_quantity">
              {Item.sale_data.list.reduce(
                (acc, item) => acc + item.quantity,
                0
              )}
            </h4>
          </div>
          <h4 className="cart_total">
            Total:
            <span className="cart_total_number">
              {Item.sale_data.list
                .reduce(
                  (acc, item) => acc + item.quantity * (item.price / 100 - item.discount / 100 || 0),
                  0
                )
                .toFixed(2)}{" "}
              €
            </span>
          </h4>
        </div>
        <div className="buttons__div">
          {/*  <Button className={"cart-button"} variant={"tertiary"} onClick={()=>{  scontext.openScanner()}}>
            SCAN
  </Button>*/}
          <Button
            className={"cart-button"}
            iconRight={true}
            variant={"danger"}
            onClick={() => {
              setHideDelete(false);
            }}
            disabled={canceled}
          >
            Anular
          </Button>
        </div>
      </div>
    </div>
  );
}
