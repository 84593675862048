import React from 'react';
import PropTypes from 'prop-types';

import './Notification.css'
import './overide.css'

const Notification = ({ title,body,show,hasCloseIcon=false,onclose, ...props }) => {

	return (
			<div style={{display:show?"":"none"}} class="mc-notification mc-notification-closable" role="status">
				<div class="mc-notification__content">
					<h3 class="mc-notification__title">{title}</h3>
					<p class="mc-notification__message">
					{body}
					</p>
			
				</div>
	{hasCloseIcon && (<button
	onClick={onclose}
      type="button"
      class="mc-notification-closable__close"
      title="Close"
    ></button>)}
			</div>
	);
};


Notification.propTypes = {
	value: PropTypes.string,
	className: PropTypes.string,
	valid: PropTypes.bool,
	invalid: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

Notification.defaultProps = {
	value: '',
	className: '',
	valid: false,
	invalid: false,
};



export default Notification;
