import React, { useState, useEffect } from "react";

import MainLogo from "./../../Assets/logo.png";
import TextInput from "./../../components/TextInput";
import PasswordInput from "./../../components/PasswordInput";
import Button from "./../../components/Button";
import { useNavigate } from "react-router-dom";
import "./LoginScreen.css";
import ModalQrCode from "../Modal/ModalQrCode";
import Notification from "./../../components/Notification";
import { useHistory } from "../../Context/HistoryContext";
import Loader from "../../components/Loader";
import { APICall } from "../../API";




export default function LoginScreen() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const {addToHistory} = useHistory();
  const [isHidden, setIsHidden] = useState(false);
  const handleLogin = () => {
    
   window.location.href = APICall.getLoginUrl()
  //  navigate("/menu");
  };

  useEffect(() => {
    addToHistory();
  }, []);
  
  return (
    <>
      <div className="login-container">
        <img className="login-logo" alt="Leroy Logo" src={MainLogo} onClick={() => {
          
          if (isHidden) {
            setIsHidden(false)
          }
          else {
            setIsHidden(true)
          }
        
          
        }} />

        {false && (
          <div>
            <h1 className="welcome-title">Bem-vindo ao</h1>
            <h1 className="welcome-brand">Compra Ágil</h1>
          </div>
        )}
        <div className="logo-input-container">
          <Notification 
            className="notification"
            show={isHidden}
            title={"Esta conta não tem permissão para aceder à Compra Ágil"}
            body={"Para solicitar o acesso, por favor faça um pedido da categoria “Acesso ou privilēgios” a partir do Digital Service Desk."}
          ></Notification>
        
        </div>
        <Button className={"login-button"} onClick={handleLogin} full={true}>
          {isHidden ? "VOLTAR" : "ENTRAR"}
        </Button>
        {<ModalQrCode isHidden={true}></ModalQrCode>}
      </div>
    </>
  );
}
